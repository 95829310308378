/* global jQuery */
/* global Router */
import $ from 'jquery';
import 'slick-carousel';
import URI from 'urijs';
import { Router } from 'director/build/director';
const jQuery = $;

(function($) {
  'use strict';
  // double slider

  $('.person-stream-slider-double').slick({
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    cssEase: 'linear',
    focusOnSelect: true,
    asNavFor: '.slider-persons-double',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: '10px',
        }
      }
    ],
    prevArrow: '<div class="arrow-left"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>' +
               '<span class="sr-only">Vorherige</span></div>',
    nextArrow: '<div class="arrow-right"><span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>' +
               '<span class="sr-only">Nächste</span>'
  });

  $('.slider-persons-double').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    adaptiveHeight: false,
    cssEase: 'linear',
    asNavFor: '.person-stream-slider-double',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ],
  });

  // detail sliders

  $('.person-stream-slider').slick({
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    cssEase: 'linear',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: '10px',
        }
      }
    ],
    prevArrow: '<div class="arrow-left"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>' +
               '<span class="sr-only">Vorherige</span></div>',
    nextArrow: '<div class="arrow-right"><span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>' +
               '<span class="sr-only">Nächste</span>'
  });

  $('.slider-persons').slick({
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    draggable: false,
    fade: true,
    cssEase: 'linear',
    asNavFor: '.slider-person-image',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          adaptiveHeight: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          adaptiveHeight: true
        }
      }
    ],
  });

  $('.slider-person-image').slick({
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    fade: true,
    swipe: false,
    centerMode: false,
    centerPadding: '80px',
    cssEase: 'linear',
    speed: 400,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          centerPadding: '40px',
        }
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: '40px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '10px',
        }
      }
    ],
    // cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    asNavFor: '.slider-persons',
  });

  function setTitle(title) {
    document.title = document.title.replace(/[^-]+(-.+)/g, title + ' \$1');
  }

  function getFirstSlideIndexBy(slider, field, value) {
    var indexes = slider.find(
      '.slick-slide:has(.item[data-slide-' + field + '="' + value + '"])'
    ).map(function() {
      var index = $(this).data('slick-index');
      if (index >= 0) {
        return index;
      }
    });
    return Math.min.apply(null, indexes);
  }

  function getPerson(pos) {
    var slider =$('.slider-persons');
    var slide = slider.find('.slick-slide').eq(pos);
    var item = slide.find('.item');

    var prev = item.data('slide-prev');
    var next = item.data('slide-next');

    if (prev) {
      prev = new URI(prev);
    }

    if (next) {
      next = new URI(next);
    }

    return {
      title: item.find('.content-full-title').text(),
      slug: item.data('slide-slug'),
      year: item.data('slide-year'),
      prev: prev,
      next: next
    };
  }

  var dontHandleYear = false;

  var personRoute = function(encodeSlug) {
    var personSlug = URI.decode(encodeSlug);
    var slider = $('.slider-persons');
    var sliderYear = $('.person-stream-slider');

    // move person slider
    var nextSlide = getFirstSlideIndexBy(slider, 'slug', personSlug);
    var currentSlide = slider.slick('slickCurrentSlide');
    var maxSlide = slider.slick('getDotCount'); // TODO: hack for slide count
    if (nextSlide === maxSlide && currentSlide === 0) {
      nextSlide = -1;
    }
    if (currentSlide === maxSlide && nextSlide === 0) {
      slider.slick('slickNext');
    } else {
      slider.slick('slickGoTo', nextSlide);
    }

    // get person from new pos
    var person = getPerson(nextSlide);

    // set page title
    setTitle(person.title);

    // move year slider
    var nextYearIndex = getFirstSlideIndexBy(sliderYear, 'year', person.year);
    var nextYearSlide = sliderYear.find('.slick-slide:not(.slick-cloned)').eq(nextYearIndex)
    var yearSlideIndex = sliderYear.slick('slickCurrentSlide');
    var currentYearItem = sliderYear.find('.slick-current:not(.slick-cloned) .item');
    var currentYear = currentYearItem.data('slide-year');
    console.log(nextYearSlide);

    if (person.year != currentYear) {
      if (sliderYear.slick('getSlick').slideCount > sliderYear.slick('slickGetOption', 'slidesToShow')) {
        dontHandleYear = true;
        sliderYear.slick('slickGoTo', nextYearIndex);
      } else {
        dontHandleYear = true;
        nextYearSlide.trigger('click');
      }
    }

    // set new links
    $('.person-arrow.arrow-left a').attr('href', person.prev);
    $('.person-arrow.arrow-right a').attr('href', person.next);
  };

  var router;

  $('.person-stream-slider').on('afterChange', yearChanged);
  $('.person-stream-slider .slick-slide:not(.slick-cloned)').on('click', yearClicked);

  function getPathFromSlug(slug) {
    var u = new URI();
    var paths = u.segmentCoded();
    paths.pop(); // TODO: fix ending slash dynamically
    paths.pop();
    paths.push(slug);
    u.segmentCoded(paths);
    return u.pathname() + '/';
  }

  function getCurrentPath(url) {
    var u;
    if (url) {
      u = new URI(url);
    } else {
      u = new URI();
    }
    return u.pathname();
  }

  function yearClicked(event) {
    var sliderYear = $('.person-stream-slider');
    var yearSlideIndex = sliderYear.find('.slick-slide:not(.slick-cloned)').index($(this));

    if (sliderYear.slick('getSlick').slideCount <= sliderYear.slick('slickGetOption', 'slidesToShow')) {
      yearChanged(event, sliderYear, yearSlideIndex);
    }

  }

  function yearChanged(event, slick, currentSlide) {
    if (dontHandleYear) {
      dontHandleYear = false;
      return ;
    }
    var slide = $('.person-stream-slider .slick-slide:not(.slick-cloned)').eq(currentSlide);
    var item = slide.find('.item');
    var year = item.data('slide-year');

    var personIndex = getFirstSlideIndexBy($('.slider-persons'), 'year', year);
    var person = getPerson(personIndex);

    var newPath = getPathFromSlug(person.slug);
    var currentPath = getCurrentPath();

    if (newPath != currentPath) {
      router.setRoute(newPath);
    }
  }

  $('.person-arrow a').click(function(e) {
    e.preventDefault();
    var url = $(this).attr('href');
    var path = getCurrentPath(url);
    // history.pushState({}, '', decodeURI(url));
    router.setRoute(path);
    // router.dispatch('on', decodeURI(u.path));
    return false;
  });

  var baseURL;

  if($('.slider-persons').length) {
    baseURL = new URI(containerURL);
    var route = {};
    route[baseURL.pathname() + '/?([^\/]+)\/$/?'] = personRoute
    console.log(route);
    router = new Router(route).configure({
      html5history: true});
    router.init();
  }

})(jQuery);

(function($) {
  'use strict';

  var hasUserbar = document.querySelector('[data-wagtail-userbar]');

  if (hasUserbar) {
    $('.slider-persons').on('afterChange', function userBar(e, slick, currentSlide) {
      var slide = $('.slider-persons .slick-slide').eq(currentSlide);
      var userbar = slide.find('[data-wagtail-userbar]')[0];
      console.log(userbar);
      var trigger = userbar.querySelector('[data-wagtail-userbar-trigger]');
      var list = userbar.querySelector('.wagtail-userbar-items');
      var className = 'is-active';
      var hasTouch = 'ontouchstart' in window;
      var clickEvent = 'click';

      if (hasTouch) {
          userbar.classList.add('touch');

          // Bind to touchend event, preventDefault to prevent DELAY and CLICK
          // in accordance with: https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
          trigger.addEventListener('touchend', function preventSimulatedClick(e) {
              e.preventDefault();
              toggleUserbar(e);
          });

      } else {
          userbar.classList.add('no-touch');
      }

      trigger.addEventListener(clickEvent, toggleUserbar, false);

      // make sure userbar is hidden when navigating back
      window.addEventListener('pageshow', hideUserbar, false);

      function showUserbar(e) {
          userbar.classList.add(className);
          list.addEventListener(clickEvent, sandboxClick, false);
          window.addEventListener(clickEvent, clickOutside, false);
      }

      function hideUserbar(e) {
          userbar.classList.remove(className);
          list.addEventListener(clickEvent, sandboxClick, false);
          window.removeEventListener(clickEvent, clickOutside, false);
      }

      function toggleUserbar(e) {
          e.stopPropagation();
          if (userbar.classList.contains(className)) {
              hideUserbar();
          } else {
              showUserbar();
          }
      }

      function sandboxClick(e) {
          e.stopPropagation();
      }

      function clickOutside(e) {
          hideUserbar();
      }
    });
  }

})(jQuery);
