import $ from 'jquery';
import 'slick-carousel';

const jQuery = $;

function loadProjectSlick(startSlide) {
  startSlide = startSlide | 0;
  $('.project-stream-slider').slick({
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    focusOnSelect: true,
    cssEase: 'linear',
    initialSlide: startSlide,
    asNavFor: '.slider-projects',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 7,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: '10px',
        }
      }
    ],
    prevArrow: '<div class="arrow-left"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>' +
               '<span class="sr-only">Vorherige</span></div>',
    nextArrow: '<div class="arrow-right"><span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>' +
               '<span class="sr-only">Nächste</span>'
  });

  $('.slider-projects').slick({
    infinite: true,
    slidesToShow: 1,
    initialSlide: startSlide,
    arrows: false,
    adaptiveHeight: true,
    fade: true,
    swipe: false,
    draggable: false,
    focusOnSelect: false,
    adaptiveHeight: true,
    cssEase: 'linear',
    asNavFor: '.project-stream-slider',
  });
}

$(document).ready(function() {
  var startSlide = $('.project-stream-slider .selected_year');
  var startIndex = 0;

  if (startSlide.length) {
    startIndex = startSlide.index();
  }

  loadProjectSlick(startIndex);

  $('.slider-projects').on('afterChange',
    function (event, slick, currentSlide) {
      var currentItem = $('.slider-projects').find(
        '.item:not(.slick-cloned)').eq(currentSlide);
      var year = currentItem.data('year');
      if (year) {
        history.pushState(null, null, '#' + year);
      } else {
        history.pushState(null, null, '#');
      }
    }
  );

  if ($('.slider-projects').length) {
    var hash = window.location.hash;
    if (hash) {
      var slideIndex = $('.slider-projects').find(
        '.item:not(.slick-cloned)[data-year="' + hash.slice(1) + '"]');
      $('.slider-projects').slick('slickGoTo', slideIndex.index());
    }
  }
});
