/*
  jQuery.reduce - a jQuery plugin for functional programming
  @author John Hunter
  created 2010-09-17
  use: $.reduce(arr, fnReduce, valueInitial);
  fnReduce is called with arguments: [valueInitial, value, i, arr]

  reduce will never be jQuery core - its not prototype :p (http://dev.jquery.com/ticket/1886)
*/
import $ from 'jquery';
import 'slick-carousel';
const jQuery = $;

(function ($) {

  $.reduce = function(arr, fnReduce, valueInitial) {
    if (Array.prototype.reduce) {
      return Array.prototype.reduce.call(arr, fnReduce, valueInitial);
    }

    $.each(arr, function(i, value) {
      valueInitial = fnReduce.call(null, valueInitial, value, i, arr);
    });
    return valueInitial;
  };

})(jQuery);

(function ($) {
  function initSlick() {
    $('.slider-home-teaser').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      speed: 500,
      centerMode: false,
      adaptiveHeight: false,
      focusOnSelect: false,
      autoplay: true,
      autoplaySpeed: 5000,
      lazyLoad: 'ondemand',
    });

    $('.slider-home-news').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      speed: 500,
      centerMode: false,
      adaptiveHeight: false,
      focusOnSelect: false,
      autoplay: true,
      autoplaySpeed: 5000,
      lazyLoad: 'ondemand',
    });

    var newsSlide = $('.slider-home-news').slick('getSlick');
    var teaserSlide = $('.slider-home-teaser').slick('getSlick');
    var newsContainer = $('.herounit-news');
    var teaserContainer = $('.herounit-teaser');

    var slideMutex = [false, false];

    function or(a, b) {
      return a || b;
    }

    function playOrPause(mutex) {
      if ($.reduce(mutex, or)) {
        newsSlide.pause();
        teaserSlide.pause();
      } else {
        newsSlide.play();
        teaserSlide.play();
      }
    }

    newsContainer.on('mouseenter.slick', function() {
      slideMutex[0] = true;
      playOrPause(slideMutex);
    });
    newsContainer.on('mouseleave.slick', function() {
      slideMutex[0] = false;
      playOrPause(slideMutex);
    });

    teaserContainer.on('mouseenter.slick', function() {
      slideMutex[1] = true;
      playOrPause(slideMutex);
    });
    teaserContainer.on('mouseleave.slick', function() {
      slideMutex[1] = false;
      playOrPause(slideMutex);
    });
  }

  $(document).ready(function() {
    if ($('.template-homepage').length) {
      // initSlick();
    }
  });

})(jQuery);
