import $ from 'jquery';
import 'slick-carousel';
const jQuery = $;

$('.newsstream-nav-slider').slick({
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  focusOnSelect: true,
  variableWidth: false,
  cssEase: 'linear',
  asNavFor: '.newsstream-slider',
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 5,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        swipeToSlide: true,
        centerMode: true,
        centerPadding: '10px',
      }
    }
  ],
  prevArrow: '<div class="arrow-left"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>' +
             '<span class="sr-only">Vorherige</span></div>',
  nextArrow: '<div class="arrow-right"><span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>' +
             '<span class="sr-only">Nächste</span>'
});

$('.newsstream-slider').slick({
  infinite: false,
  slidesToShow: 1,
  arrows: false,
  fade: true,
  swipe: false,
  cssEase: 'linear',
  asNavFor: '.newsstream-nav-slider',
});

$('.newsslider-teaser-image').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  speed: 500,
  centerMode: false,
  adaptiveHeight: false,
  focusOnSelect: false,
  autoplay: true,
  autoplaySpeed: 5000,
  asNavFor: '.newsslider-teaser',
});

$('.newsslider-teaser').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  speed: 500,
  centerMode: false,
  adaptiveHeight: true,
  focusOnSelect: false,
  autoplay: true,
  autoplaySpeed: 5000,
  asNavFor: '.newsslider-teaser-image',
});

$(document).ready(function() {
  $('.newsstream-slider').on('afterChange',
    function (event, slick, currentSlide) {
      var currentItem = $('.newsstream-slider').find(
        '.item:not(.slick-cloned)').eq(currentSlide);
      var year = currentItem.data('year');
      if (year) {
        history.pushState(null, null, '#' + year);
      } else {
        history.pushState(null, null, '#');
      }
    }
  );

  if ($('.newsstream-slider').length) {
    var hash = window.location.hash;
    if (hash) {
      var slideIndex = $('.newsstream-slider').find(
        '.item:not(.slick-cloned)[data-year="' + hash.slice(1) + '"]');
      $('.newsstream-slider').slick('slickGoTo', slideIndex.index());
    }
  }
});
