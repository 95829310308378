// Google Maps Scripts
// Draw Map & Markers
import $ from 'jquery';
import Modernizr from './custom-modernizr.js';
import markerIcon from '../images/map-marker.png';

function init() {
  'use strict';

  // Basic options for a simple Google Map
  var geocoder = new google.maps.Geocoder();

  // Get LatLong position and call marker callback, with geolocation
  function geocodeAddress(project, markerCall) {

    if (project.geolocation) {
      markerCall(project.geolocation);
      return ;
    }

    return ;
  }

  var center = {'lat': 51.336123, 'lng': 12.368566};

  try {
    center = mapCenter;
  } catch (ReferenceError) {

  }

  // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
  var mapOptions = {
    // How zoomed in you want the map to start at (always required)
    zoom: 9,

    // The latitude and longitude to center the map (always required)
    center: new google.maps.LatLng(center),

    // Disables the default Google Maps UI components
    disableDefaultUI: false,
    scrollwheel: true,
    draggable: !Modernizr.touch,

    // How you would like to style the map.
    // This is where you would paste any style found on Snazzy Maps.
    styles: [
      {
          'featureType': 'administrative',
          'elementType': 'all',
          'stylers': [
              {
                  'visibility': 'simplified'
              }
          ]
      },
      {
          'featureType': 'administrative.locality',
          'elementType': 'labels',
          'stylers': [
              {
                  'color': '#ba5858'
              }
          ]
      },
      {
          'featureType': 'administrative.neighborhood',
          'elementType': 'labels',
          'stylers': [
              {
                  'color': '#e57878'
              }
          ]
      },
      {
          'featureType': 'landscape',
          'elementType': 'geometry',
          'stylers': [
              {
                  'visibility': 'simplified'
              },
              {
                  'color': '#fcfcfc'
              }
          ]
      },
      {
          'featureType': 'poi',
          'elementType': 'geometry',
          'stylers': [
              {
                  'visibility': 'simplified'
              },
              {
                  'color': '#fcfcfc'
              }
          ]
      },
      {
          'featureType': 'poi',
          'elementType': 'labels',
          'stylers': [
              {
                  'visibility': 'off'
              }
          ]
      },
      {
          'featureType': 'poi.attraction',
          'elementType': 'labels',
          'stylers': [
              {
                  'visibility': 'off'
              }
          ]
      },
      {
          'featureType': 'road.highway',
          'elementType': 'geometry',
          'stylers': [
              {
                  'visibility': 'simplified'
              },
              {
                  'color': '#dddddd'
              }
          ]
      },
      {
          'featureType': 'road.highway',
          'elementType': 'labels',
          'stylers': [
              {
                  'visibility': 'off'
              }
          ]
      },
      {
          'featureType': 'road.highway.controlled_access',
          'elementType': 'labels',
          'stylers': [
              {
                  'visibility': 'off'
              }
          ]
      },
      {
          'featureType': 'road.arterial',
          'elementType': 'geometry',
          'stylers': [
              {
                  'visibility': 'simplified'
              },
              {
                  'color': '#dddddd'
              }
          ]
      },
      {
          'featureType': 'road.arterial',
          'elementType': 'labels',
          'stylers': [
              {
                  'visibility': 'off'
              }
          ]
      },
      {
          'featureType': 'road.local',
          'elementType': 'geometry',
          'stylers': [
              {
                  'visibility': 'simplified'
              },
              {
                  'color': '#eeeeee'
              }
          ]
      },
      {
          'featureType': 'road.local',
          'elementType': 'labels.text.fill',
          'stylers': [
              {
                  'color': '#ba5858'
              }
          ]
      },
      {
          'featureType': 'transit.station',
          'elementType': 'labels.text.fill',
          'stylers': [
              {
                  'color': '#ba5858'
              }
          ]
      },
      {
          'featureType': 'transit.station',
          'elementType': 'labels.icon',
          'stylers': [
              {
                  'hue': '#ff0036'
              }
          ]
      },
      {
          'featureType': 'water',
          'elementType': 'geometry',
          'stylers': [
              {
                  'visibility': 'simplified'
              },
              {
                  'color': '#dddddd'
              }
          ]
      },
      {
          'featureType': 'water',
          'elementType': 'labels.text.fill',
          'stylers': [
              {
                  'color': '#ba5858'
              }
          ]
      }
    ]
  };

  // Get the HTML DOM element that will contain your map
  // We are using a div with id='map' seen below in the <body>
  var mapElement = document.getElementById('map-canvas-1');

  $('.map-googlemap').each(function() {
    var el = $(this);
    if (el.parent().is(':visible')) {
      mapElement = el[0];
    }
  });

  // Create the Google Map using out element and options defined above
  var map = new google.maps.Map(mapElement, mapOptions);

  // Custom Map Marker Icon - Customize the map-marker.png file to customize your icon
  // var myLatLng = new google.maps.LatLng(50.810250, 12.977236);
  var markers = [];
  var infoWindowOpen;
  var isSingle = false;

  function loadMarkers(url) {
    $.getJSON(url, function(projects) {
      markers = [];

      if (!Array.isArray(projects)) {
        projects = [projects];
        isSingle = true;
      }

      $.each(projects, function(i, project) {
        geocodeAddress(project, function(myLatLng) {
          var contentString;

          if (!isSingle) {
            contentString = '<div class="marker-box">'+
              '<div class="marker-year">'+ project.year + '</div>' +
              '<div class="marker-recipient">'+ project.recipient.name + '</div>' +
              '<div class="marker-short-desc"><a href="' + project.detail_url + '">' +
              project.short_description + '</a></div></div>';
            placeMarker(myLatLng, project, contentString);
            return ;
          }

          // Search for Google's office in Australia.
          var request = {
            location: myLatLng,
            radius: '1000',
            query: project.address
          };

          var service = new google.maps.places.PlacesService(map);

          service.textSearch(request, function(results, status) {
            var query = ', ' + project.address;
            if (status == google.maps.places.PlacesServiceStatus.OK) {
              query ='&query_place_id=' + results[0].place_id;
            }

            contentString = '<div class="marker-box">'+
              '<div class="marker-recipient">'+ project.short_description + '</div>' +
              '<div class="marker-short-desc">' +
              '<a target="_blank" href="https://www.google.com/maps/search/?api=1&query=' +
              myLatLng.lat.toFixed(7) + ',' + myLatLng.lng.toFixed(7) + query +
              '">Bei Google Maps zeigen ></a></div></div>';
            placeMarker(myLatLng, project, contentString);
          });
        });
      });
    });
  }

  function placeMarker(myLatLng, project, contentString) {
      var infoWindow = new google.maps.InfoWindow({
        content: contentString,
        // maxWidth: 300
      });

      var marker = new google.maps.Marker({
        position: myLatLng,
        map: map,
        animation: google.maps.Animation.DROP,
        title: project.title,
        icon: markerIcon
      });

      marker.addListener('click', function() {
        if (infoWindowOpen) {
          infoWindowOpen.close();
        }
        infoWindow.open(map, marker);
        infoWindowOpen = infoWindow;
      });

      markers.push(marker);
  }

  function setMapOnAll(map) {
    for (var i = 0; i < markers.length; i++) {
      markers[i].setMap(map);
    }
  }

  function resize() {
    var $map = $(mapElement);
    $map.height($(window).height() - $map.offset().top - 10);
    google.maps.event.trigger(map, 'resize');
  }

  function redrawMarkers() {
    setMapOnAll(null);
    var filters = $('.content-filter-form').serialize();
    console.log(filters);
    loadMarkers(projectsURL + '?' + filters);
  }

  $(window).resize(resize);
  $(document).ready(function() {
    // loadMarkers(projectsURL);
    redrawMarkers();
    resize();
    $('#select-years, #select-tags').on('changed.bs.select', redrawMarkers);
  });
}

$(document).ready(function() {
  if (typeof(google) !== 'undefined') {
    setTimeout(() => {
      init();
    }, 500);
  }
});
